import styled from 'styled-components';
import _ from 'lodash';

const weights = {
    light: 'lighter',
    normal: 'normal',
    medium: 500,
    bold: 'bold',
}

export default styled.div.attrs(props => ({
    // validate font family exists in theme -- default to main
    familyValue: _.has(props.theme.typography.family, props.family)
        ? props.theme.typography.family[props.family]
        : 'inherit',
    // validate color exists in palette
    colorValue:
        _.has(props.theme.palette, props.color)
            ? props.theme.palette[props.color].main
            : 'inherit',
    // validate size level exists in theme
    sizeValue:
        [1,2,3,4,5,6].includes(parseInt(props.size, 10))
            ? props.theme.typography.size[props.size]
            : 'inherit',
    // validate font weight
    weightValue:
        _.has(weights, props.weight)
            ? weights[props.weight]
            : 'inherit'
}))`
    margin: 0;
    padding: 0;
    font-family: ${props => props.familyValue};
    color: ${props => props.colorValue};
    font-size: ${props => props.sizeValue};
    font-weight: ${props => props.weightValue};
    font-style: ${props => props.italic ? 'italic' : 'inherit'};
    text-align: ${props => props.align || 'inherit'};
    display: ${props => props.contents ? 'contents' : props.block ? 'block' : 'inline-block'};
    line-height: ${props => props.lineHeight || '1.2em'};
    letter-spacing: ${props => props.spacing || 'inherit'};
    text-transform: ${props => props.textTransform || 'inherit'};

    * {
        letter-spacing: inherit;
        text-align: inherit;
        font-weight: inherit;
        font-style: inherit;
        color: inherit;
        font-family: inherit;
    }
`;