import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Flex from 'src/components/base/flex';
import { ButtonBase } from 'src/components/base/button';
import Typography from 'src/components/base/typography';
import Icon from 'src/components/base/icon';
import MenuIcon from 'src/images/icons/menu.svg';
import ExitIcon from 'src/images/icons/exit.svg';
import Nav from "./header.css";
import MobileMenu from 'src/components/mobileMenu/mobileMenu';
import Brand from 'src/components/brand';
import Logo from 'src/images/icons/logo.svg';

const navMenu = [
  { route: '/', text: 'Home' },
  { route: '/contact', text: 'Contact' },
  { route: '/portfolio', text: 'Portfolio' },
  { route: '/blog', text: 'Blog' }
]

const Header = ({ siteTitle, page }) => {

  const [menuOpen, setMenuOpen] = React.useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  const renderMenu = () => navMenu.map((item, i) => (
    <li key={i}>
      <Link
        to={item.route}
        active={page && page === item.route ? "true" : "false"}
        data-hover="noline"
      >
        {item.text}
      </Link>
    </li>
  ));

  return (
    <Nav.root>

      {/* Header Left */}
      <Flex.box align="center">
        <Link to="/" style={{ transform: 'translate(0, 4px)' }}>
          <Brand size="3" />
        </Link>
      </Flex.box>

      {/* Header Right */}
      <Typography as="nav" family="display" weight="medium" size="5">

        {/* Desktop view */}
        <Nav.row as="ul" data-hidden="mobile tablet">
          {renderMenu()}
        </Nav.row>

        {/* Mobile view */}
        <Nav.row data-hidden="desktop">
          <ButtonBase onClick={openMenu} nopadding>
            <Icon size="2rem">
              <MenuIcon />
            </Icon>
          </ButtonBase>

          <MobileMenu open={menuOpen} onClose={closeMenu}>
            <Typography family="display" size="5" spacing="1px" weight="medium" contents>
              <Nav.column as="ul">
                <li style={{ textAlign: 'right' }}>
                  <ButtonBase onClick={closeMenu} nopadding>
                    <Icon size="2rem" position="0 50%">
                      <ExitIcon />
                    </Icon>
                  </ButtonBase>
                </li>
                <li style={{ margin: '.2rem 0' }}>
                  <Icon size="2.5rem" style={{ margin: 0 }}>
                    <Logo />
                  </Icon>
                </li>
                {renderMenu()}
              </Nav.column>
            </Typography>
          </MobileMenu>
        </Nav.row>
      </Typography>

    </Nav.root>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  page: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
