import styled from 'styled-components';

export const FlexItem = styled.div`
    flex-basis: ${props => props.basis || 'auto'};
    flex-grow: ${props => props.fluid ? '1' : props.grow || 0};
    flex-shrink: ${props => props.fluid ? '1' : props.shrink || 0};
`;

export const FlexBox = styled.div.attrs(props => ({
    directionValue: props.direction || 'row',
}))`
    display: flex;
    flex-direction: ${props => props.directionValue};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};
    height: 100%;
    width: 100%;
`;

export const FlexReverse = styled(FlexBox).attrs(props => ({
    breakpointValue: props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop
}))`
    @media only screen and (min-width: ${props => props.breakpointValue}px) {
        flex-direction: ${props => props.directionValue + '-reverse'};
    }
`;

export default {
    box: FlexBox,
    item: FlexItem,
    reverse: FlexReverse,
}