import chroma from 'chroma-js';

const black = "#000000",
      white = "#ffffff";


function buildColor(color, contrast) {
  return {
    main: color,
    bright: chroma(color).brighten(2).hex(),
    dark: chroma(color).darken().hex(),
    contrast,
    alpha: {
      light: chroma(color).alpha(0.08).hex(),
      medium: chroma(color).alpha(0.4).hex(),
      heavy: chroma(color).alpha(0.8).hex(),
    },
  }
}

const palette = {
  white: buildColor(white, black),
  black: buildColor(black, white),
  dark: buildColor("#303336", white),
  silver: buildColor("#E9EEF2", black),
  gray: buildColor("#717171", black),
  primary: buildColor("#0065F2", white),
  success: buildColor("#2ecc71", white),
  danger: buildColor("#e74c3c", white),
}

export default {
  palette,
  typography: {
    family: {
      main: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
      display: `'Univia Pro', Verdana, Arial, sans-serif`,
    },
    size: {
      rem: {
        default: '16px',
        mobile: '14px',
        tablet: '16px',
        desktop: '16px',
      },
      1: '3rem',
      2: '2rem',
      3: '1.6rem',
      4: '1.3rem',
      5: '1rem',
      6: '0.8rem',
    },
    color: {
      main: palette.black,
      light: "#5D676E",
      link: palette.primary,
      invert: palette.white,
    },
  },
  spacing: [
    0,
    '.5rem',
    '1rem',
    '1.5rem',
    '2rem',
  ],
  radius: {
    small: '2px',
    default: '12px',
    large: '24px',
  },
  shadow: {
    sm: '0px 2px 1px - 1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    md: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    lg: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  },
  transition: {
    default: 200,
    slow: 500,
  },
  breakpoint: {
    mobile: 300,
    tablet: 768,
    desktop: 992,
  },
  // specific components
  header: {
    height: '5.5rem',
  },
  image: {
    maxWidth: 480,
  },
  grid: {
    gap: '4rem', 
  },
  card: {
    maxWidth: '450px',
    minHeight: '400px',
    maxHeight: '500px',
  },
  blog: {
    list: {
      maxWidth: '740px',
    },
    card: {
      width: {
        small: '240px',
        large: '290px',
      },
      height: '420px',
      image: {
        size: '8rem',
      },
      title: {
        height: '2em',
      },
      prune: {
        title: 32,
        description: 112,
      }
    },
  },
  article: {
    figure: {
      height: '320px',
    }
  },
  centerElement: {
    width: '420px',
  }
}