import { Link } from 'gatsby';
import React from 'react';
import Container from 'src/components/base/container';
import Grid from 'src/components/base/grid';
import Typography from 'src/components/base/typography';
import Flex from 'src/components/base/flex';
import Divider from 'src/components/base/divider';
import { FooterRoot } from './footer.css';
import Brand from 'src/components/brand';
import LinkExternal from 'src/components/base/linkExternal';

const nav = [
  { href: "/", text: 'Home' },
  { href: "/contact", text: 'Contact' },
  { href: "/portfolio", text: 'Portfolio' },
  { href: "/blog", text: 'Blog' },
  { href: "/logos", text: 'Logos' },

]

const Footer = () => (
  <FooterRoot breakpoint="tablet">
    <Container small="500px" large="800px">
      <Flex.box>
        {/* Left logo and contact */}

        <Flex.item fluid>
          <Flex.box direction="column">
            {/* Brand */}
            <Flex.item fluid>
              <Brand size="2" />
            </Flex.item>

          </Flex.box>
        </Flex.item>

        <Divider
          vertical
          size="7.5rem"
          margin="1rem"
          opacity=".3"
          attach="end"
        />

        {/* Right sitemap */}
        <Flex.item fluid>
          <Flex.box as="ul" justify="flex-end" direction="column" align="right">
            {nav.map((item, i) => (
              <Typography
                as="li"
                weight="medium"
                size="6"
                color="gray"
                key={i}
                textTransform="uppercase"
                style={{ marginTop: i > 0 ? ".4rem" : 0 }}
              >
                <Link to={item.href} data-hover="noline" data-color="none">
                  {item.text}
                </Link>
              </Typography>
            ))}
          </Flex.box>
        </Flex.item>
      </Flex.box>
    </Container>
  </FooterRoot>
)

export default Footer;
