import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'src/components/base/typography';
import Logo from 'src/images/icons/logo_text.svg';

const BrandRoot = styled.figure`

  height: 1.2em;

  &, * {
    margin: 0;
    max-height: 100%;
  }

  * {
    height: 100%;
    width: auto;
  }
`;

const Brand = ({ size, color }) => {
  return (
    <Typography size={size} color={color}>
      <BrandRoot>
        <Logo />
      </BrandRoot>
    </Typography>
  );
}

Brand.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Brand;
