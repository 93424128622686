import styled from 'styled-components';

export const H1 = styled.h1`
    font-family: ${props => props.theme.typography.family.display};
    font-weight: bold;
    text-align: center;
    font-style: italic;
    display: block;
    font-size: ${props => props.theme.typography.size[2]};
    margin: 0;

    @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
        font-size: ${props => props.theme.typography.size[1]};
    }
`;

export const H2 = styled.h2`
    font-family: ${props => props.theme.typography.family.display};
    font-size: ${props => props.theme.typography.size[3]};
    font-weight: bold;
    margin: 0;

    @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
        font-size: ${props => props.theme.typography.size[2]};
    }
`;

export default {
    h1: H1,
    h2: H2,
}