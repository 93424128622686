import styled, { css } from 'styled-components';
import _ from 'lodash';

const flatGradient = (color) => `linear-gradient(${color}, ${color})`;

export const ButtonBase = styled.button`
    position: relative;
    padding: ${props => props.nopadding ? 0 : '.5rem 1rem'};
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    display: ${props => props.block || props.responsive ? 'block' : 'inline-block'};
    width: ${props => props.block || props.responsive ? '100%' : 'auto'};
    overflow: hidden;
    background: transparent;
    border: none;
    font-family: inherit;
    outline: none;
    
    ${props => props.series ? 'margin-top: 1rem;' : ''}
    
    &:not(:disabled):hover {
        background: ${props => props.hoverColor || props.theme.palette.black.alpha.light};
    }

    &:disabled {
        color: ${props => props.theme.palette.gray.alpha.medium} !important;
        border-color: ${props => props.theme.palette.gray.alpha.medium} !important;
        cursor: default !important;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
        ${props => props.responsive ? `
            display: inline-block;
            width: auto;
        ` : ''}
        ${props => props.series ? css`
            margin-top: ${props.block ? '1rem' : 0};
            margin-left: ${props.block ? 0 : '1rem'};
        `: ''}
    }
`;

export const ButtonFlat = styled(ButtonBase).attrs(props => ({
    bgColor: props.theme.palette[props.dark ? 'silver' : 'white'],
}))`
    background: ${props => props.bgColor.main};
    color: ${props => props.theme.palette.primary.main};
    border-radius: ${props => props.theme.radius.small};

    &:hover {
        background: ${props => flatGradient(props.theme.palette.primary.alpha.light) + ',' + flatGradient(props.bgColor.main)};
    }
`;

export const Button = styled(ButtonBase).attrs(props => ({
    // validate color exists in palette
    colorValue: props.theme.palette[
        _.has(props.theme.palette, props.color)
            ? props.color
            : 'primary'
    ],
}))`
    color: ${props => props.outline ? props.colorValue.main : props.colorValue.contrast};
    border-radius: ${props => props.round ? props.theme.radius.default : props.theme.radius.small};
    background: ${props => props.outline ? props.theme.palette.white.main : props.colorValue.main};
    border: ${props => props.outline ? `1px solid ${props.colorValue.bright || props.colorValue.main}` : 'none'};

    &:not(:disabled):hover {
        background: ${props => props.outline
            ? `${props.colorValue.alpha.light}, ${props.theme.palette.white.main}`
            : props.colorValue.contrast === props.theme.palette.black.main
                ? props.colorValue.bright
                : props.colorValue.dark
        };
    }
`;

export default Button;