import styled from 'styled-components';

export const HeaderRoot = styled.header`
  background-color: ${props => props.theme.palette.dark.main};
  color: ${props => props.theme.palette.dark.contrast};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1.5rem;
  height: ${props => props.theme.header.height};
  position: relative;
  z-index: 3;

  * {
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    a {
      color: ${props => props.theme.palette.dark.contrast};
    }
  }


`

export const NavRow = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  a {
    margin: 0 1rem;
    white-space: nowrap;
    
    &:hover {
      color: ${props => props.theme.palette.white.alpha.heavy};
    }

    &[active="true"] {
      cursor: auto;
      color: ${props => props.theme.palette.white.alpha.medium};   
    }
  }
`;

export const NavColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 260px;

  li {
    width: 100%;
    text-align: left;
    padding: .8rem 1rem;
    line-height: 1.5em;
  }

  a {
    margin: 0;
  }
`;

export default {
  root: HeaderRoot,
  row: NavRow,
  column: NavColumn,
};