import styled from 'styled-components';

export default styled.div`
    width: ${props => props.width || props.size || 'auto'};
    height: ${props => props.height || props.size || 'auto'};
    margin: ${props => props.margin || 0};
    border-radius: ${props => props.circle ? '50%' : 0};
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.bgImage ? `url(${props.bgImage})` : 'none'};
    background-size: cover;

    svg, img {
        width: 100%;
        height: 100%;
        object-fit: ${props => props.cover ? 'cover' : 'contain'};
        object-position: ${props => props.position || 'center center'};
        margin: 0;
    }
    svg {
        fill: currentColor;
    }
`;