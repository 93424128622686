import styled from 'styled-components';
import _ from 'lodash';

const Section = styled.div.attrs(props => {
    
    const hasColor = _.has(props.theme.palette, props.color);

    return {
        // validate color exists in palette
        hasColor,
        colorValue: hasColor ? props.theme.palette[props.color] : 'transparent',
        padBase: props.padding || 'auto',
    }
})`
    background-color: ${props => props.hasColor ? props.colorValue.main : props.colorValue};
    color: ${props => props.hasColor ? props.colorValue.contrast : 'inherit'};
    padding: ${props => `${props.pady} ${props.padx}`};
    padding-top: ${props => props.top || props.y || props.padBase};
    padding-left: ${props => props.left || props.x || props.padBase};
    padding-right: ${props => props.right || props.x || props.padBase};
    padding-bottom: ${props => props.bottom || props.y || props.padBase};
    display: ${props => props.inline ? 'inline-block' : 'block'};
    max-width: 100%;
`;

export default Section;