import styled from 'styled-components';

export default styled.hr.attrs(props => {

    const margin = props.margin || '1rem';
    
    return {
        marginTop: !props.vertical ? margin : props.attach === 'start' ? 0 : 'auto',
        marginBottom: !props.vertical ? margin : props.attach === 'end' ? 0 : 'auto',
        marginLeft: props.vertical ? margin : props.attach === 'start' ? 0 : 'auto',
        marginRight: props.vertical ? margin : props.attach === 'end' ? 0 : 'auto',
        size: props.size || '100%',
        width: props.width || '1px',
    }
})`
    opacity: ${props => props.opacity || 0.6};
    color: inherit;
    margin-top: ${props => props.marginTop} !important;
    margin-bottom: ${props => props.marginBottom} !important;
    margin-left: ${props => props.marginLeft} !important;
    margin-right: ${props => props.marginRight} !important;
    width: ${props => props.vertical ? 'auto' : props.size};
    height: ${props => props.vertical ? props.size : 'auto'};
    border-top: ${props => props.vertical ? 'none' : `${props.width} solid`};
    border-left: ${props => props.vertical ? `${props.width} solid` : 'none'};
`;