import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    overflow-x: hidden;
    font-family: ${props => props.theme.typography.family.main};
  }
  body {
    margin: 0;
    background-color: ${props => props.theme.palette.white.main};
  }

  p {
    margin-bottom: 2rem;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    
    &, &:visited {
      color: ${props => props.theme.palette.primary.main};
    }

    &[data-color="primary"] {
      color: ${props => props.theme.palette.primary.main};
    }

    &[data-color="none"] {
      color: inherit;
    }

    &:disabled, &[disabled="true"] {
      color: ${props => props.theme.palette.primary.gray} !important;
      cursor: default !important;
      pointer-events: none !important;
      touch-action: none !important;
    }

    &:not([active="true"]):not([disabled="true"]) {

      &:hover {
        text-decoration: underline;
      }

      &[data-hover^="color"],
      &[data-hover*=" color"] {
        &:hover {
          color: ${props => props.theme.palette.primary.main};
        }
      }

      &[data-hover^="fancyline"],
      &[data-hover*=" fancyline"] {
        position: relative;
        text-decoration: none;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          border-bottom: 1px solid currentColor;
          transition: width ${props => props.theme.transition.default}ms;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      &[data-hover^="noline"],
      &[data-hover*=" noline"] {
        &:hover {
          text-decoration: none;
        }
      }

      &[data-hover^="none"] {
        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }

  }

  button {
    border: none;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint.tablet - 1}px) {
    html {
      font-size: ${props => props.theme.typography.size.rem.mobile};
    }

    [data-hidden^="mobile"],
    [data-hidden*=" mobile"] {
      display: none !important;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoint.tablet}px) and (max-width: ${props => props.theme.breakpoint.desktop - 1}px) {
    html {
      font-size: ${props => props.theme.typography.size.rem.default};
    }
    
    [data-hidden^="tablet"],
    [data-hidden*=" tablet"] {
      display: none !important;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoint.desktop}px) {
    html {
      font-size: ${props => props.theme.typography.size.rem.default};
    }
    
    [data-hidden^="desktop"],
    [data-hidden*=" desktop"] {
      display: none !important;
    }
  }
`