/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Header from 'src/components/header/header';
import Footer from 'src/components/footer/footer';
import { ThemeProvider } from 'styled-components';
import theme from 'src/theme';
import { GlobalStyle } from './main.css';
import Flex from 'src/components/base/flex';
import 'src/fonts/fonts.css';
import 'src/styles/base.scss';
import 'katex/dist/katex.min.css';

const Layout = ({ children, page }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Flex.box direction="column" style={{minHeight: '100vh'}}>
          <Header page={page} />
          <Flex.item as="main" grow="1" className="app-body" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}>
            {children}
          </Flex.item>
          <Footer />
        </Flex.box>
      )}
    />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
