import React from 'react';
import PropTypes from 'prop-types';

// the styled component is rendered in a separate React component
// to insert the NewTab-safe HTML attributes (target, rel)
const LinkExternal = ({ children, ...props }) => {
    return (
        <a target="_blank" rel="noopener noreferrer" {...props}>
            {children}
        </a>
    );
}

LinkExternal.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
}

export default LinkExternal;