import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FlexBox } from './flex';
import _ from 'lodash';

// validate breakpoint
const determineBreakpoint = (theme, device) => {
    return theme.breakpoint[device] || theme.breakpoint.desktop;
}

const gapCss = css`
    margin-top: ${props => props.theme.grid.gap};
`;

const Row = styled(FlexBox).attrs(props => ({
    breakpointValue: determineBreakpoint(props.theme, props.breakpoint),
}))`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    ${props => props.gap ? gapCss : ''}

    @media only screen and (min-width: ${props => props.breakpointValue}px) {
        flex-direction: row${props => props.reverse ? '-reverse' : ''};
    }
`;

export const GridColumn = styled.div.attrs(props => {
    const size = parseInt(props.size, 10);
    return {
        breakpointValue: determineBreakpoint(props.theme, props.breakpoint),
        // validate size
        flexValue: _.range(1, 13).includes(size)
            ? `0 0 ${size * (100 / 12)}%`
            : '1 1 auto',
    }
})`
    flex: 0 0 auto;

    ${props => props.gap ? gapCss : ''}

    @media only screen and (min-width: ${props => props.breakpointValue}px) {
        flex: ${props => props.flexValue};
        margin-top: 0;
    }
`;

export const GridRow = ({ breakpoint, children, reverse, gap }) => {

    return (
        <Row breakpoint={breakpoint} reverse={reverse} gap={gap}>
            {React.Children.map(children, (child) => {
                return child ? React.cloneElement(child, { breakpoint }) : child;
            })}
        </Row>
    );
}

Row.propTypes = {
    breakpoint: PropTypes.oneOf(['tablet', 'desktop']),
    children: PropTypes.node.isRequired,
    reverse: PropTypes.bool,
    gap: PropTypes.bool,
}

Row.defaultProps = {
    breakpoint: 'desktop',
    reverse: false,
    gap: false,
}

export default {
    row: GridRow,
    column: GridColumn,
}