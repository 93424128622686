import React from 'react';
import PropTypes from 'prop-types';
import Menu from './mobileMenu.css';
import useTheme from 'src/components/base/useTheme';

const MobileMenu = ({ open, onClose, children }) => {

    const theme = useTheme();
    const contentRef = React.useRef();

    const [state, setState] = React.useState({
        display: false,
        content: false,
    });

    const setDisplay = (display) => {
        setState(s => ({
            ...s,
            display,
        }));
    }

    const showContent = (content) => {
        setState(s => ({
            ...s,
            content,
        }));
    }

    const catchBubble = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleClose = (e) => {
        catchBubble(e);
        onClose(e);
    }

    // respond to open control state
    React.useEffect(() => {
        if (open) {
            setDisplay(true);
        } else {
            // init close animation
            showContent(false);
        }
    }, [open]);

    React.useEffect(() => {
        if (state.display) {
            // trigger show content after opening menu
            showContent(true);
        }
    }, [state.display]);

    React.useEffect(() => {
        if (state.content) {
            contentRef.current.focus();
        } else {
            // trigger turn off display after close animation
            setTimeout(() => {
                setDisplay(false);
            }, theme.transition.default);
        }
    }, [state.content]);

    return (
        <Menu.root open={state.display} onClick={catchBubble}>
            <Menu.overlay open={state.content} onClick={handleClose} />
            <Menu.content open={state.content} ref={contentRef} onClick={catchBubble} onBlur={handleClose}>
                {children}
            </Menu.content>
        </Menu.root>
    );
}

MobileMenu.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
}

export default MobileMenu;