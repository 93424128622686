import styled from 'styled-components';

export const FooterRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.silver.main};
  padding: 2rem;

  ul {
    margin: 0;
  }
  
  a:hover {
    color: ${props => props.theme.palette.gray.dark};
  }

  @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
    flex-direction: row;
  }
`;
