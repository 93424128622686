import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const Container = styled.div.attrs(props => ({
    // validate breakpoint exists in theme -- defaults to desktop
    breakpointValue: props.theme.breakpoint[
        _.has(props.theme.breakpoint, props.breakpoint)
            ? props.breakpoint
            : 'desktop'
    ],
}))`
    margin: auto;
    width: ${props => props.limit || props.small || '100%'};
    max-width: 100%;
    padding: ${props => props.padding || props.paddingSmall ? '0 1.5rem' : 0};

    @media only screen and (min-width: ${props => props.breakpointValue}px) {
        width: ${props => props.limit || props.large || props.breakpointValue + 'px'};
        padding: ${props => props.padding || props.paddingLarge ? '0 1.5rem' : 0};
    }
`;

export const ContainerCenter = ({ children, ...props}) => (
    <Container limit="380px" {...props} paddingSmall>
        {children}
    </Container>
)

export default Container;