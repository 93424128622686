import styled from 'styled-components';

export const MenuRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: ${props => props.open ? 1 : 0};
    pointer-events: ${props => props.open ? 'auto' : 'none'};
`;

export const MenuOverlay = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: ${props => props.open ? 'auto' : 'none'};
    background: ${props => props.theme.palette.black.alpha.heavy};
    transition: ${props => props.theme.transition.default}ms;
    opacity: ${props => props.open ? 1 : 0};
`;

export const MenuContent = styled.div`
    position: absolute;
    z-index: 2;
    background-color: ${props => props.theme.palette.dark.main};
    top: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    min-width: 100px;
    padding: 1.5rem 2.5rem;
    transition: ${props => props.theme.transition.default}ms;
    transform: translate(${props => props.open ? 0 : '100%'}, 0);

    li:first-child {
        padding: .5rem 0;
    }

    li:not(:first-child) {
        padding: .5rem 1rem;

        &:not(:nth-child(2)) {
            border-top: 1px solid ${props => props.theme.palette.white.alpha.light};
            &:last-child {
                border-bottom: 1px solid ${props => props.theme.palette.white.alpha.light};
            }
        }
    }

    a {
        margin: 0;

        &[active="true"] {
            text-decoration: none;
        }
    }
`;

export default {
    root: MenuRoot,
    overlay: MenuOverlay,
    content: MenuContent,
}